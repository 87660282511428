<template>
  <div class="rdjj_box" ref="rdjj_box">
    <h1 class="rdjjh1">热点聚焦</h1>
    <h1 class="h1Hone"><img src="@/assets/icon2.png">热点聚焦</h1>
    <!-- pc端热点聚焦 -->
    <div class="rdjjcarousel">
      <!-- 分页器，自定义 -->
      <ul ref="Pagerdot">
        <li v-for="(item,i) in rdjjList.list" :key="i" @click="Jumprotation(i)"></li>
      </ul>
      <el-carousel :height="767/128+'rem'" class="carousel_" interval="5000" ref="carousel" indicator-position="none"
        @change="carouselChange">
        <el-carousel-item v-for="(item,i) in rdjjList.list" :key="i">
          <div class="top_img" @click="push_xq(item[0])">
            <img :src="item[0].thumb?baseUrl+item[0].thumb:''" alt="">
            <p>{{item[0].title}}</p>
          </div>
          <div class="buttom_img">
            <nav v-for="(item1,ii) in item" :key="ii" @click="push_xq(item1)">
              <!-- 两边竖线条 -->
              <span></span><span></span><span></span>
              <!-- 两边竖线条end -->
              <h1>{{item1.title}}</h1>
              <div class="right_date">
                <span>{{item1.created_at.slice(0,10)}}</span>
                <img src="../../../assets/icon.png" alt="">
              </div>
            </nav>
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>

    <!-- 移动端热点聚焦 -->
    <div class="rdjj">
      <ul>
        <li v-for="(item,i) in rdjjList.list[0]" :key="i" @click="push_xq(item)">
          <img :src="baseUrl+item.thumb" v-if="i == 0">
          <p>{{item.title}}</p>
          <span>{{item.created_at.slice(0,10)}}<i></i></span>
        </li>
        <li v-for="(item2,i2) in rdjjList.list[1]" :key="i2" @click="push_xq(item2)">
          <img :src="baseUrl+item2.thumb" v-if="i == 0">
          <p>{{item2.title}}</p>
          <span>{{item2.created_at.slice(0,10)}}<i></i></span>
        </li>
      </ul>
    </div>

    <h1 class="h1_2">{{ routeData.title1}}</h1>
    <h1 class="h1_phone"><img src="@/assets/icon2.png">{{ routeData.title1}}</h1>
    <div class="xyxw_box">
      <ul>
        <li v-for="(item,i) in xyxwList.list" :key="i" @click="push_xq(item)">
          <img :src="item.is_top==1?
         'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618117Tx22xh':
         item.is_new==1?'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618150nKswsR':
         'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618259YxAm28'" :class="item.is_top==1||item.is_new==1?'top_top_new':'top_no_topNew'">
          <span>{{item.title}}</span>
          <a>{{item.created_at.slice(0,10)}}</a>
        </li>
      </ul>
      <!-- 底部分页 -->
      <div class="pagination">
        <keep-alive>
          <Pagination :total="from.total" :page="from.page" :limit="from.limit" @pageChange="pageChanges" />
        </keep-alive>
      </div>
    </div>
    <!-- 移动端学院新闻 -->
    <div class="xyxw_box_phone">
      <div class="header_x"></div>
      <div class="nav_x">
      <ul>
        <li v-for="(item,i) in xyxwList.list" :key="i" @click="push_xq(item)">
          <nav>
            <!-- <img :src="i==0?'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618117Tx22xh':'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618150nKswsR'" v-if="i == 0 || i == 1"> -->
            <img :src="item.is_top==1?
         'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618117Tx22xh':
         item.is_new==1?'https://xq-images.obs.cn-north-4.myhuaweicloud.com/2022070618150nKswsR':
         ''" v-if="item.is_top ==1 || item.is_new == 1" />
            <span>{{item.title}}</span>
          </nav>
          <a>{{item.created_at.slice(0,10)}}</a>
        </li>
      </ul>
      </div>
      <!-- 底部分页 -->
      <div class="pagination">
      <Pagination :total="from.total" :page="from.page" :limit="from.limit" @pageChange="pageChanges" />
      </div>
    </div>
  </div>
</template>
<script setup>
import * as axios from '@/api/API.js'

import Pagination from '@/components/Pagination.vue'
import{ useRoute,useRouter } from 'vue-router'
import {ref,reactive,watch,onMounted,nextTick, onUnmounted} from 'vue'
import base64_ from '@/hooks/useBase64text.js'
const route = useRoute()
const router = useRouter()
let routeData = JSON.parse(base64_.decode(route.params.obj))
const baseUrl = process.env.VUE_APP_BASE_URL
const rdjj_box = ref(null)
const rdjjList = reactive({
  list:[]
})
// 获取热点聚焦数据
const getrdjjList = async () => {
  const {data:res} = await axios.getHttp("/api/rdjjList")
  if(res.code == 200){
    rdjjList.list = res.data
    rdjj_box.value.style.opacity = 1
    rdjj_box.value.style.transform = 'translateY(0)'
  }
}
getrdjjList()
// 学院新闻对应参数
let from = reactive({
  total:0,
  page:1,
  limit:15,
  type_id: routeData.type_id,
})
const xyxwList = reactive({
  list:[]
})
// 获取学院新闻数据
const getxyxwList = async () => {
  const {data:res} = await axios.getHttp("/api/article",from)
  if(res.code == 200){
    from.total = res.data.total
    xyxwList.list = res.data.data
    // 确定数据加载完毕并且dom更新后，热点聚焦轮播默认为1的索引设置背景
    if(!Pagerdot.value.children[0])return
    Pagerdot.value.children[0].style.backgroundColor = '#B19A86'
  }
}
// getxyxwList()
// 把page缓存到本地，以免页面刷新回到第一页
let editFrompage = () => {
    const sessStor = JSON.parse(window.sessionStorage.getItem('page'))
    sessStor?from.page = sessStor:from.page = 1
    from.type_id = routeData.type_id
    getxyxwList()
}
editFrompage()
// 分页
const pageChanges = (eee) => {
  from.page = eee
  window.sessionStorage.setItem('page',eee)
  getxyxwList()
}

const carousel = ref(null);
// 获取热点聚焦分页器ul的dom元素
const Pagerdot = ref(null)
let phone_pagination_show = ref(false)
onMounted(() => {
  document.documentElement.scrollTop = 0
  rdjj_box.value.style.opacity = 0
  rdjj_box.value.style.transform = 'translateY(800px)'
  setTimeout(() => {
    carousel.value.setActiveItem(0)
  }, 800)
})
onUnmounted(() => {
  if(!route.params.obj) return
  if(JSON.parse(base64_.decode(route.params.obj)).article_wz_id){}
      else{
        window.sessionStorage.setItem('page',1)
      }
})

// 热点聚焦轮播发生改变
const carouselChange = (e) => {
  const li = Pagerdot.value.children
  for (let i = 0; i < li.length; i++) {
  li[i].style.backgroundColor = ''
  }
  li[e].style.backgroundColor = '#B19A86'
}
// 点击小圆点跳转对应位置
const Jumprotation = (ee) => {
carousel.value.setActiveItem(ee)
}
let push_xq = (row) => {
  router.push({
    name: "Article_xq_time",
    params: {obj:base64_.encode(JSON.stringify({
      title1: row.type_name,
      article_wz_id: row.id,
      type_id: 1,
      index: 0
    }))}
  })
}

</script>

<style lang="less" scoped>
.top_top_new{
          width: 40/@maxw;
          height: 45%;
        }
        .top_no_topNew{
          margin: 0 0 0 10/@maxw;
          width: 11/@maxw !important;
          height: 35%;
        }
@maxw:128rem;
.loding_{
  font-size: 50/@maxw;
  color: #fff;
  margin: 0 0 0 50/@maxw;
}
.rdjj_box{
  box-sizing: border-box;
  padding: 0;
  font-size: 15/@maxw;
  margin: 0 auto;
  transition: ease .8s;
  opacity: 0;
  >h1{
    // font-family: Source Han Sans CN-Bold, Source Han Sans CN;
    // font-weight: bold;
    font-size: 39/@maxw;
    font-family: fantBold;
    font-weight: 300;
    color: #174994;
    margin: 0;
    width: 100%;
    margin: 10/@maxw auto;
    padding: 0 0 10/@maxw 0;
    opacity: 1;
    img{
      display: none;
    }
  }
  .rdjjcarousel{
    display: block;
    position: relative;
    margin: 30/@maxw auto;
    width: 1104/@maxw;
    height: 767/@maxw;
    cursor: pointer;
    background: linear-gradient(311deg, #B8C9E3 0%, #517DBE 100%);
    >ul{
          margin: 0;
          padding: 0;
          width: 100%;
          position: absolute;
          bottom: -50/@maxw;
          display: flex;
          justify-content: center;
          list-style: none;
          cursor: pointer;
          >li{
              box-sizing: border-box;
              height: 13/@maxw;
              width: 13/@maxw;
              border-radius: 50%;
              // background: #B19A86;
              margin: 0 15/@maxw 0 0;
              border: 2px solid #B19A86;
          }
    }
    .carousel_{
      height: 100%;
      position: relative;
      top: 15/@maxw;
      right: 15/@maxw;
    }
    .top_img{
      position: relative;
      // width: 100%;
      width: 1088/@maxw;
      height: 582/@maxw;
      transition: ease .5s;
      img{
        position: absolute;
        top: 0;
        left: 0;
        z-index: -999;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
      p{
        position: absolute;
        bottom: 80/@maxw;
        left: 30/@maxw;
        margin: 0;
        padding: 0;
        font-size: 50/@maxw;
        font-family: Source Han Sans CN-Bold, Source Han Sans CN;
        font-weight: bold;
        color: #FFFFFF;
        text-shadow: 0px 3px 6px rgba(0, 0, 0, 0.55);
        width: 900/@maxw;
      }
    }
    .top_img:hover{
      transform: scale(1.02);
    }
    .buttom_img{
      position: absolute;
      width: 100%;
      height: 195/@maxw;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      nav{
        position:relative;
        max-width: 338/@maxw;
        min-width: 337/@maxw;
        flex:1;
        // width: 377/@maxw;
        height: 195/@maxw;
        background-image: url("../../../assets/jujiao1.png");
        background-repeat: no-repeat;
        background-size: 100%;
        padding: 0 10/@maxw 0 20/@maxw;
        transition: ease .5s;
        h1{
          margin-top: 7.5%;
          font-size: 24/@maxw;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #174994;
           word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            overflow: hidden;
        }
        .right_date{
          position: absolute;
          display: flex;
          align-items: center;
          bottom:18%;
          right: 20/@maxw;
          span{
            margin-right: 10/@maxw;
          }
          img{
            width: 20%;
            height: 20%;
            transition: ease .5s;
          }
          >img:hover{
            transform:translateX(10/@maxw);
          }
        }
      }
      >nav:hover{
        background-image: url("../../../assets/ejjujiao2.png");
        background-repeat: no-repeat;
        background-size: 100%;
        color: #fff;
        //  transform:scale(1.02);
        h1{
          color: #fff;
        }
        >span:nth-child(1){
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 3/@maxw;
        height: 195/@maxw;
        background-color: #D1BAA6;
        transition: ease .5s;
      }
      >span:nth-child(2){
        content: "";
        position: absolute;
        top: 0;
        right: 0;
        width: 3/@maxw;
        height: 195/@maxw;
        background-color: #D1BAA6;
        transition: ease .5s;
      }
      >span:nth-child(3){
        content: "";
        position: absolute;
        top: 15%;
        left: 3/@maxw;
        width: 10/@maxw;
        height: 66/@maxw;
        background-color: #D1BAA6;
        transition: ease .5s;
      }
      }
    }
  }

  .rdjjcarousel::after{
     width: 15/@maxw;
    height: 770/@maxw;
    background-color: #fff;
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    z-index: 999999999;
    }
  .h1_2{
    margin-top: 80/@maxw;
    color: #174994;
    border-bottom:2/@maxw solid #174994;
  }
  .h1Hone,.h1_phone,.xyxw_box_phone,.rdjj{
    display: none;
  }
  .xyxw_box{
    box-sizing: border-box;
    margin: 30/@maxw auto;
    width: 100%;
    cursor: pointer;
      >ul{
      margin: 0;
      padding: 0;
      >li{
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 50/@maxw;
        margin: 10/@maxw 0;
        border-bottom: 1/@maxw dotted #BABABA;
        transition: ease .3s;
        padding: 0 0 0 10/@maxw;
        // >img{
        //   width: 40/@maxw;
        //   height: 45%;
        // }
        >span{
          flex: 1;
          margin: 0 15/@maxw 0 30/@maxw;
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
          font-size: 22/@maxw;
          font-family: Source Han Sans CN-Medium;
          font-weight: 500;
          color: #5A5A5A;
        }
        >a{
          width: 100/@maxw;
          white-space: nowrap;
        }
      }
      >li:hover{
        box-shadow: 0 0 8/@maxw rgb(90, 90, 90);
        margin-left: 3/@maxw;
      }
    }
  }
}
.pagination{
  display: flex;
  justify-content: center;
  margin: 6% 0 0 0; 
}
@media screen and (max-width:750px) {
  @maxw_:750/100vw;
  .rdjj_box{
    .rdjjh1{
      display: none;
      font-size: 35/@maxw_;
      border: none !important;
    }
    .rdjjcarousel,.h1_2,.xyxw_box{
      display: none;
    }
    .h1Hone{
     display: flex;
      align-items: center;
      font-size: 35/@maxw_;
       >img{
        display: block;
        width: 11/@maxw_;
        height: 19/@maxw_;
        margin-right: 12/@maxw_;
      }
    }
    
    .h1_phone{
      margin-top: 30/@maxw_;
      padding-bottom: 10/@maxw_;
      font-size: 35/@maxw_;
      display: flex;
      align-items: center;
      // font-family: fantBold;
      // font-weight: 300;
       >img{
        display: block;
        width: 11/@maxw_;
        height: 19/@maxw_;
        margin-right: 12/@maxw_;
      }
    }
    .rdjj{
      display: block;
      margin-top: 24/@maxw_;
      >ul{
        padding: 0;
        margin: 0;
        width: 100%;
        >li{
          position: relative;
          width: 100%;
          height: 120/@maxw_;
          background-image: url('../../../assets/rdjjbackgPhone.png');
          background-size: 100% 100%;
          background-repeat: no-repeat;
          margin: 16/@maxw_ 0;
          >p{
            width: 644/@maxw_;
            // width: 500/@maxw_;
            height: 45/@maxw_;
            padding: 25/@maxw_;
            font-size: 24/@maxw_;
            font-family: Source Han Sans CN;
            font-weight: bold;
            color: #174994;
            word-break: break-all;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            overflow: hidden;
          }
          >span{
              display: flex;
              align-items: center;
              position: absolute;
              right: 26/@maxw_;
              bottom: 20/@maxw_;
              font-size: 18/@maxw_;
              font-family: Source Han Sans CN;
              >i{
              display: inline-block;
              width: 28/@maxw_;
              height: 28/@maxw_;
              background-image: url('../../../assets/icon.png');
              background-size: 100% 100%;
              background-repeat: no-repeat;
              margin-left: 10/@maxw_;
          }
          }
        }
        
      >li:nth-child(1){
        position: relative;
        width: 100% !important;
        height: 366/@maxw_ !important;
        background: linear-gradient(to right, #174994 0%, #387DE2 100%);
        padding: 8/@maxw_ 0 0 0;
        >img{
          position: absolute;
          width: 100%;
          height: 100%;
          vertical-align: top;
        }
        >p{
          overflow: hidden;
          padding: 0;
          margin: 0;
          position: absolute;
          bottom: 35/@maxw_;
          left: 30/@maxw_;
          z-index: 99;
          width: 624/@maxw_;
          height: 94/@maxw_;
          font-size: 38/@maxw_;
          font-family: Source Han Sans CN-Bold, Source Han Sans CN;
          font-weight: bold;
          color: #FFFFFF;
          line-height: 50/@maxw_;
         }
         >span{
          display: flex;
          align-items: center;
          position: absolute;
          right: 30/@maxw_;
          bottom: 20/@maxw_;
          z-index: 99999;
          font-weight: 500;
          font-size: 18/@maxw_;
          color: #FFFFFF;
          >i{
            display: inline-block;
            width: 28/@maxw_;
            height: 28/@maxw_;
            background-image: url('../../../assets/icon_.png');
            background-size: 100% 100%;
            background-repeat: no-repeat;
            margin-left: 18/@maxw_;
          }
         }
      }
      }
    }
  }
  
  .xyxw_box_phone{
    display: block !important;
    .header_x{
      margin: 0;
      width: 100%;
      height: 8/@maxw_;
      background: linear-gradient(to right, #174994 0%, #387DE2 100%);
      
    }
    .nav_x{
      width: 100%;
      background: #F4F4F4;
      padding: 0 0 60/@maxw_ 0;
    }
    ul{
      margin: 0;
      padding: 0 25/@maxw_;
      >li{
        box-sizing: border-box;
        width: 100% !important;
        height: 120/@maxw_ !important;
        background-image: linear-gradient(to right, #9A9A9A 0%, #9A9A9A, transparent 80%);
        background-size: 9/@maxw_ 1px;
        background-repeat: repeat-x;
        background-position:bottom;
        display: flex;
        flex-direction: column;
        justify-content: center;
        >nav{
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          >img{
          width: 45/@maxw_ !important;
          height: 24/@maxw_  !important;
          margin-right: 10/@maxw_;
        }
        
        span{
          display: inline-block;
          font-size: 24/@maxw_ !important;
          color: #292929;
          height: 30/@maxw_ !important; 
          word-break: break-all;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 1;
          overflow: hidden;
        }
        }
        >a{
          margin-top: 9/@maxw_;
          width: 100/@maxw_ !important;
          font-size: 18/@maxw_ !important;
          font-family: Source Han Sans CN-Medium;
          white-space: nowrap;
          color: #5F5F5F;
          margin-right: 30/@maxw_;
        }
      }
    }
  }
}
</style>